.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-builder-group-header {
  padding: 0 !important;
}

.MuiListItemButton-root.Mui-selected {
  background-color: #f5f6f7 !important;
}

.MuiTablePagination-menuItem {
  text-align: center;
}

.MuiStepIcon-text {
  font-size: 0.8rem;
}

.MuiTableCell-body.MuiTableCell-sizeMedium {
  padding: 10px 0px 6px 15px ;
}

.expandRowCell.MuiTableCell-body.MuiTableCell-sizeMedium {
  padding: 0 !important;
}
/* .css-vubbuv {

  margin-top: 10px !important;
} */
.custom-textarea{
  width: 100%;
  height: calc(100vh - 12%);
  padding: 12px 20px;
  box-sizing: border-box;
  background: #fff;
  border: none;
  font-size: 16px;
  resize: none;
  padding-bottom: calc(100vh - 200px);
}