@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@700&family=Inter:wght@600&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Rubik&display=swap');
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.box,
#fromio .formio-form .wizard-page {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  border: 1px solid #ebedef9e;
  box-shadow: 0px -3px 12px #7278820f;
}

#fromio .formio-form .wizard-page h1,
#fromio .formio-form .wizard-page h2,
#fromio .formio-form .wizard-page h3,
#fromio .formio-form .wizard-page h4,
#fromio .formio-form .wizard-page h5,
#fromio .formio-form .wizard-page h6 {
  margin: 0;
  padding: 0;
}

#fromio .formio-form {
  font-family: 'Roboto', 'sans-serif' !important;
}

#fromio .formio-form .wizard-page {
  padding-bottom: 65px;
}

#fromio .formio-form > div:first-child {
  display: grid;
  grid-template-columns: 20% 80%;
}

.step {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: cream;
}

.v-stepper {
  position: relative;
}

/* regular step */
.step .circle {
  background-color: white;
  border: 2px solid #d4d8dd;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.step .line {
  top: 20px;
  left: 10px;
  height: 100%;
  position: absolute;
  border-left: 2px dotted #d4d8dd;
}

.step.completed .circle {
  visibility: visible;
  background-color: #3487f3;
  background-image: url('assets/icons/check.svg');
  border-color: #3487f3;
}

.step.completed .line {
  border-left: 2px dotted #3487f3;
}

.step.active .circle {
  visibility: visible;
  border-color: #3487f3;
}

.step.empty .circle {
  visibility: hidden;
}

.step.empty .line {
  /*     visibility: hidden; */
  /*   height: 150%; */
  top: 0;
  height: 150%;
}

.step:last-child .line {
  border-left: 2px dotted white;
  z-index: -1; /* behind the circle to completely hide */
}

.wizard-nav-container .content {
  margin-left: 20px;
  display: inline-block;
  text-align: left;
  letter-spacing: 0px;
  color: #6b727d;
  opacity: 1;
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
}

.wizard-nav-container .completed .content {
  color: #6b727d;
}
.wizard-nav-container .active .content {
  color: #39404b;
  font-size: 14px;
  line-height: 19px;
  font-family: 'Roboto';
}

.wizard-nav-container {
  background: #fff;
  border-radius: 8px;
  width: 90%;
  min-height: 380px;
  padding: 30px 20px;
  margin-left: 0;
  border: 1px solid #ebedef9e;
  box-shadow: 0px -3px 12px #7278820f;
}

.formio-form .formio-wizard-nav-container {
  position: absolute;
  right: 12px;
  bottom: 5px;
}

.formio-form .btn-wizard-nav-cancel,
.formio-form .btn-wizard-nav-submit {
  display: none;
}

/* File Uploader */
.fileUploader {
  min-width: 100% !important;
  /* height: 400px !important; */
  border: dashed 2px #3487F3 !important;
  background: #F8FBFF;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-family: 'Roboto', 'sans-serif' !important;
  margin-top: 12px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.fileUploader.active {
  border: dashed 1px #2684FF !important;
}

.fileUploader .wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fileUploader .fileWarpper {
  width: 128px;
  height: 128px;
  border-radius: 8px;
  background: #f5f6f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

.fileUploader .fileWarpper img {
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 8px;
}

.fileUploader .fileWarpper.active {
  background: #1e9d66;
  color: #fff;
}

/* rotate animation */

.loading {
  animation: animName 2s linear infinite;
}

@keyframes animName {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.css-1ss2ei0-MuiContainer-root {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.rdw-editor-wrapper {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
}

.rdw-editor-main {
  padding: 0 15px;
}

.MuiStepLabel-labelContainer span {
  font-size: 13px;
}
.choices__list--dropdown,
.choices__list[aria-expanded] {
  overflow: scroll !important;
  height: 200px !important;
}
.formio-component-datagrid {
  height: 350px !important;
}

.css-1vooibu-MuiSvgIcon-root {
  width: 1.5em !important;
  height: 1.5em !important;
}
.css-1cw4hi4 {
  user-select: none;
  width: 1.5em !important;
  height: 1.5em !important;
}

.formio-choices.form-group.is-open,
.formio-choices.formio-form-group {
  min-width: 480px !important;
}
.formio-choices.form-group,
.formio-choices.formio-form-group {
  min-width: 220px !important;
}
.css-uvum4a-MuiTableCell-root {
  vertical-align: text-top !important;
}
.css-17ob23u-MuiTableCell-root {
  vertical-align: text-top !important;
}
/* .css-i4bv87-MuiSvgIcon-root {
  margin-top: 10px !important;
} */
.css-mkwsl5 {
  vertical-align: text-top;
}

.css-vnkopk-MuiStepLabel-iconContainer {
  align-items: baseline !important;
}

/* .css-155nyw6-MuiButton-endIcon {

  margin-top: -6px !important;
} */

@media screen and (min-width: 1920px) {
  .css-9sl4yc {
    width: 300px !important;
    height: 200px !important;
  }
}

.table__head {
  background: #f5f6f8;
  font-weight: bold;
  position: sticky;
  top: 0;
}

.table__td {
  background: white;
  border-left: 1px solid #f0f0f2;
  font-weight: 600;
  color: black;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #f0f0f2;
  padding: 8px;
  /* -webkit-box-shadow: 9px 18px 30px 0px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: 9px 18px 30px 0px rgba(0, 0, 0, 0.27);
  box-shadow: 9px 18px 30px 0px rgba(0, 0, 0, 0.27); */
}
#customer table {
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 25px;
  background: linear-gradient(
    to bottom,
    #e0e0e0 0%,
    #e1e1e1 10%,
    #e5e5e5 47%,
    #e7e7e7 100%
  );
  -webkit-border-radius: 10px 10px 10px 10px;
  -moz-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  box-sizing: border-box;
}
#customers th {
  background-color: #404040;
  color: #ffffff;
  -webkit-box-shadow: inset 0px -5px 10px -5px #e0dcdc; /* Add a bottom shadow */
  -moz-box-shadow: inset 0px -5px 10px -5px #e0dcdc;
  box-shadow: inset 0px -5px 5px -5px #e0dcdc;
  z-index: 99999;
}

/* 
#customers tr:hover {
  background-color: #ddd;
} */

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #f5f6f8;
  color: white;
}
.selected-item {
  background-color: #e0e0e0; /* Set your desired background color */
}

/* Scroll bar stylings */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #E2E5E9;
}

.custom-switch input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}

.custom-switch label {
	cursor: pointer;
	text-indent: -9999px;
	width: 36px;
	height: 20px;
	background: grey;
	display: block;
	border-radius: 100px;
	position: relative;
}

.custom-switch label:after {
	content: '';
	position: absolute;
	top: 1px;
	left: 1px;
	width: 18px;
	height: 18px;
	background: #fff;
	border-radius: 90px;
	transition: 0.3s;
}

.custom-switch input:checked + label {
	background: #2684FF;
}

.custom-switch input:checked + label:after {
	left: calc(100% - 1px);
	transform: translateX(-100%);
}

.custom-switch label:active:after {
	width: 40px;
}


/* .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
  padding: 8.5px 14px !important;
} */